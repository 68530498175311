/* global JS_REMOTE_URL */

import merge from 'lodash/merge';
import localisation from '@openclassrooms/localisation';

window.App.remoteUrl = '/modules';

const JS_REMOTE_URL_KEY = 'jsRemoteUrl';
const CONFIG_KEY = 'config';

if (window.localStorage.getItem(JS_REMOTE_URL_KEY)) {
  window.App.remoteUrl = window.localStorage.getItem(JS_REMOTE_URL_KEY);
} else if (JS_REMOTE_URL) {
  window.App.remoteUrl = JS_REMOTE_URL;
}

const configFetch = fetch(`/config.json?n=${Date.now()}`);
// eslint-disable-next-line import/no-unresolved
const mainFetch = import('sdzv4/main');

const { country } = window.App.user;
const localisationConfigPromise = localisation.load(country);

Promise.all([localisationConfigPromise, configFetch, mainFetch]).then(
  async ([localisationConfig, configResponse, { default: boot }]) => {
    const config = await configResponse.json();
    let customConfig = {};

    if (window.localStorage.getItem(CONFIG_KEY)) {
      customConfig = JSON.parse(window.localStorage.getItem(CONFIG_KEY));
    }

    boot(merge(config, customConfig), localisationConfig);
  }
);
