import { frenchTerritoryIsoCodes } from '@openclassrooms/location/constants';

const ConfiguredCountries = ['FR', 'US', 'GB'] as const;

type HandledCountry = (typeof ConfiguredCountries)[number];

type CountryGroup = {
  mainCountryIsoCode: HandledCountry;
  territories: readonly string[];
};

const COUNTRY_GROUPS: CountryGroup[] = [
  {
    mainCountryIsoCode: 'FR',
    territories: frenchTerritoryIsoCodes,
  },
];

function isHandledCountry(country: any): country is HandledCountry {
  return ConfiguredCountries.includes(country);
}

export default function getConfigurationCountry(targetCountry: string): HandledCountry | 'INT' {
  if (isHandledCountry(targetCountry)) {
    return targetCountry;
  }

  const foundCountryGroup = COUNTRY_GROUPS.find((countryGroup) =>
    countryGroup.territories.includes(targetCountry)
  );

  if (foundCountryGroup) {
    return foundCountryGroup.mainCountryIsoCode;
  }

  return 'INT';
}
