export const franceIsoCode = 'FR';

export const unitedStatesIsoCode = 'US';

export const frenchTerritoryIsoCodes = [
  'GP',
  'MQ',
  'GF',
  'RE',
  'PM',
  'YT',
  'BL',
  'MF',
  'WF',
  'PF',
  'NC',
  'TF',
] as const;

export const americanTerritoryIsoCodes = ['AS', 'GU', 'MP', 'PR', 'UM', 'VI'] as const;

export const allFranceIsoCodes = [franceIsoCode, ...frenchTerritoryIsoCodes] as const;

export const allUnitedStatesIsoCode = [unitedStatesIsoCode, ...americanTerritoryIsoCodes] as const;

export type FrIsoCodes = typeof allFranceIsoCodes;
export type UsIsoCodes = typeof allUnitedStatesIsoCode;

export type FrIsoCode = FrIsoCodes[number];
export type UsIsoCode = UsIsoCodes[number];

export type IsoCodes = FrIsoCode[] | UsIsoCode[];
export type IsoCode = IsoCodes[number];
