import getConfigurationCountry from './services/getConfigurationCountry';

async function load(country: string) {
  let config = {};

  const configurationCountry = getConfigurationCountry(country);

  if (configurationCountry) {
    config = (
      await import(/* webpackChunkName: "countryConfig" */ `./config/${configurationCountry}`)
    ).default;
  }

  return config;
}

// eslint-disable-next-line import/prefer-default-export
export default { load };
